import React, { useState } from "react"
import Layout from "../../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../../components/modal"
import Button from "../../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import Navbar from "../../components/navbars/courseLandingNavbarNoMenu"
import CheckoutButton from "../../components/buttons/checkoutButton"
import ReactGA from "react-ga"

const HeroBackground = styled.div`
  display: block;
  background: none;
  background-size: cover;
  background-position: center center;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 150px;
  height: 100%;
  h1 {
    color: var(--white);
    text-align: center;
  }
  h5 {
    color: var(--white);
    padding-top: 15px;
    font-family: "roboto-mono";
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  p {
    color: var(--white);
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    h1,
    p {
      color: var(--white);
    }

    .cta-hero-invite {
      margin-bottom: 20px;
    }
  }
`

const Diretions = styled.div`
  ol {
    margin-left: 40px;
    margin-top: 20px;
  }
`

const VideoContainer = styled(Container)`
  margin-top: -200px;
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  .capacity-container {
    p {
      margin-bottom: 5px;
      font-size: 16px;
      text-align: center;
    }
  }
`

const ModalContainer = styled.div`
  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  .cta-container a {
    width: 100%;
    display: block;
  }
`

const EarlyDecision = ({ location }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  return (
    <Layout>
      <HeroBackground>
        <Navbar location={location} />
        <PSModal
          handleClose={handleClose}
          handleShow={e => handleShow(e)}
          show={show}
        >
          <ModalContainer>
            <h2>Pay Deposit</h2>
            <p>
              You can pay $986 to hold your spot for the upcoming March 21, 2021
              programs. There are only 20 spots available in total. Paying this
              before <strong>November 30, 2020</strong> will grant you an extra
              $500 off tuition. <br></br>
              <br></br> Would you like to hold your spot for the Full-time
              Program, or Part-time Program?
            </p>
            <div className="cta-container">
              <CheckoutButton
                fireGTMEvent={fireGTMEvent}
                text={"Full Time"}
                location={location}
                price={`${process.env.GATSBY_FT_DEPOSIT_PRICE}`}
              />
              <CheckoutButton
                fireGTMEvent={fireGTMEvent}
                text={"Part Time"}
                location={location}
                price={`${process.env.GATSBY_FT_DEPOSIT_PRICE}`}
              />
            </div>
          </ModalContainer>
        </PSModal>
        <HeroContainer>
          <Row>
            <Col md={12}>
              <h1>The Admissions Code Challenge_</h1>
              {/* <p><em>To be completed by <strong>02/01/21</strong> at the latest.</em></p> */}
            </Col>
          </Row>
        </HeroContainer>
      </HeroBackground>
      <section>
        <VideoContainer>
          <Row>
            <Col className="video-left" md={12}>
              <iframe
                title="directions"
                width="100%"
                height="435"
                src="https://www.youtube.com/embed/s1jkae7hDDg"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              ></iframe>
              <Diretions>
                <h3>Directions on Completing the Code Challenge</h3>
                <ol>
                  <li>Watch the video above, in full.</li>
                  <li>
                    Click{" "}
                    <a
                      href="https://github.com/projectshft/code-challenge"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{" "}
                    to access the code challenge prompt.
                  </li>
                  <li>
                    Submit the code challenge using the button below, or{" "}
                    <a
                      href="https://projectshift.typeform.com/to/x4qoLVZz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      this link
                    </a>{" "}
                    (they're the same).
                  </li>
                  <li>
                    Note that if you have any syntax errors (or do not follow
                    direction for the code challenge), the tests will
                    automatically fail and will reward you 0 points.
                  </li>
                  <li>
                    For support as it relates to the code challenge, reach out
                    to Parsity's tech lead via email here:
                    <strong>brian@parsity.io</strong>
                  </li>
                </ol>
              </Diretions>
              <div className="cta-container">
                <a
                  href="https://parsity.typeform.com/to/x4qoLVZz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    classToAdd="cta-syllabus"
                    fireGTMEvent={fireGTMEvent}
                    handleCTAClick={() => {}}
                    text={"Submit Your Code Challenge"}
                  />
                </a>
              </div>
            </Col>
          </Row>
        </VideoContainer>
      </section>
    </Layout>
  )
}

export default EarlyDecision
